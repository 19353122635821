import React from 'react';
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.svg';

const Header = () => {
	return (
		<>
			<header className="wrapper">
				<nav className="navbar navbar-expand-lg center-nav transparent navbar-light">
					<div className="container flex-lg-row flex-nowrap align-items-center">
						<div className="navbar-brand w-100 py-2">
							<Link to="/">
								<img
									src={Logo}
									srcSet={Logo}
									alt="Codevixens Logo"
									className="img-fluid w-10 py-1"
								/>
							</Link>
						</div>
						<div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
							<div className="offcanvas-header d-lg-none">
								<Link to="/">
									<img
										src={Logo}
										srcSet={Logo}
										alt="Codevixens Logo"
										className="img-fluid w-10"
									/>
								</Link>
								<button
									type="button"
									className="btn-close btn-close-white"
									data-bs-dismiss="offcanvas"
									aria-label="Close"
								></button>
							</div>
							<div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
								<ul className="navbar-nav">
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle py-lg-auto py-2 fs-14"
											href="#"
											data-bs-toggle="dropdown"
										>
											Admissions
										</a>
										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="/admissions-process"
												>
													Admission Process
												</Link>
											</li>
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="/admissions-policy"
												>
													Admissions Policy
												</Link>
											</li>
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="/financial-assistance"
												>
													Financial Assistance
												</Link>
											</li>
										</ul>
									</li>
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle py-lg-auto py-2 fs-14"
											href="#"
											data-bs-toggle="dropdown"
										>
											Our Educational Programs
										</a>
										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="/#codevixens-fellowship"
												>
													Codevixens Fellowship
												</Link>
											</li>
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="/#codevixens-academy"
												>
													Codevixens Academy
												</Link>
											</li>
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="/#codevixens-20d-challenge"
												>
													Codevixens 20 Days Challenge
												</Link>
											</li>
										</ul>
									</li>
									<li className="nav-item">
										<Link
											className="nav-link py-lg-auto py-2 fs-14"
											to="/admissions-policy#tuition-fee-policies"
										>
											Tuition Fees
										</Link>
									</li>
									<li className="nav-item">
										<Link
											className="nav-link py-lg-auto py-2 fs-14"
											to="/about-us"
										>
											Why Codevixens
										</Link>
									</li>
									<li className="nav-item dropdown">
										<a
											className="nav-link dropdown-toggle py-lg-auto py-2 fs-14"
											href="#"
											data-bs-toggle="dropdown"
										>
											Resources
										</a>
										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													target={'_blank'}
													to="https://www.youtube.com/@codevixensacademy"
												>
													Videos
												</Link>
											</li>
											<li className="nav-item">
												<Link
													className="dropdown-item fs-14"
													to="https://medium.com/the-codevixens-blog"
												>
													Visit Our Blog
												</Link>
											</li>
										</ul>
									</li>
								</ul>
								<div className="d-lg-none mt-auto pt-6 pb-6 order-4">
									<a
										href="mailto:programs@codevixens.org"
										className="link-inverse"
									>
										programs@codevixens.org
									</a>
									{/* <br /> 00 (123) 456 78 90 <br /> */}
									<nav className="nav social social-white mt-4">
										<a href="https://twitter.com/codevixens">
											<i className="uil uil-twitter"></i>
										</a>
										<a href="https://www.facebook.com/people/Codevixens-Academy/100088290937780/#">
											<i className="uil uil-facebook-f"></i>
										</a>
										<a href="https://www.linkedin.com/company/codevixens-academy">
											<i className="uil uil-linkedin"></i>
										</a>
										<a href="https://www.instagram.com/codevixenshq">
											<i className="uil uil-instagram"></i>
										</a>
									</nav>
								</div>
							</div>
						</div>
						<div className="navbar-other w-100 ms-auto d-flex justify-content-end">
							<nav className="nav social social-muted text-end d-none d-lg-block">
								<a href="https://twitter.com/codevixens">
									<i className="uil uil-twitter"></i>
								</a>
								<a href="https://www.facebook.com/people/Codevixens-Academy/100088290937780/#">
									<i className="uil uil-facebook-f"></i>
								</a>
								<a href="https://www.linkedin.com/company/codevixens-academy">
									<i className="uil uil-linkedin"></i>
								</a>
								<a href="https://www.instagram.com/codevixenshq">
									<i className="uil uil-instagram"></i>
								</a>
							</nav>
							<ul className="navbar-nav flex-row align-items-center ms-5 d-lg-none">
								<li className="nav-item">
									<button className="hamburger offcanvas-nav-btn">
										<span></span>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</nav>
				{/* <!-- /.navbar --> */}
				<div
					className="alert bg-dark text-white show rounded-0 mb-0 text-lg-center py-1"
					role="alert"
				>
					<div className="container">
						<div className="alert-inner d-flex justify-content-center align-items-center p-0 fs-13">
							<span className="badge badge-lg bg-white text-dark text-uppercase rounded me-2">
								NEWS
							</span>{' '}
							Learn Data Warehousing with Snowflake: Join our upcoming workshop{' '}
							<a
								href="https://subscribepage.io/Akkhaw90"
								className="alert-link link-violet hover ms-1"
							>
								Learn More
							</a>
							!
						</div>
						{/* <!-- /.alert-inner --> */}
					</div>
					{/* <!-- /.container --> */}
				</div>
			</header>
		</>
	);
};

export default Header;
